@import '../variables';

//////////////////////////////////////////
// Buttons
//////////////////////////////////////////

.btn {
  .icon {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    margin-left: -0.75rem;
    margin-right: -0.25rem;
    vertical-align: text-bottom;
    .rtl & {
      margin-right: -0.75rem;
      margin-left: -0.25rem;
    }
  }

  &.btn-sm {
    .icon {
      width: 28px;
      height: 28px;
      margin-top: -0.25rem;
      margin-bottom: -0.25rem;
      margin-left: -0.5rem;
      margin-right: -0.25rem;
      vertical-align: middle;
      .rtl & {
        margin-right: -0.5rem;
        margin-left: -0.25rem;
      }
    }
  }

  &.btn-lg {
    .icon {
      margin-top: -0.35rem;
      margin-bottom: -0.35rem;
      margin-left: -0.75rem;
      margin-right: -0.15rem;
      .rtl & {
        margin-right: -0.75rem;
        margin-left: -0.15rem;
      }
    }
  }

  // Widths
  &.w-132 {
    min-width: 132px;
  }
}

.btn-link {
  icon {
    vertical-align: middle;
  }
}

// Button default
.btn-default {
  @include button-variant(
    transparent,
    $body-color,
    transparent,
    $brand-color-main-muted,
    transparent,
    $brand-color-main
  );
  color: $body-color;

  &:hover {
    color: $brand-color-main-muted;
  }
  &:focus {
    color: $body-color;
    border-color: $brand-color-main;
  }
  &:active {
    color: $text-dark;
  }
  .icon {
    vertical-align: text-bottom;
  }
}

// Button Yellow
.btn-yellow {
  @include button-variant(
    transparent,
    $brand-color-main,
    transparent,
    $brand-color-main-muted,
    $brand-color-main,
    $brand-color-main
  );
  color: $brand-color-main;

  &:hover {
    color: $brand-color-main-muted;
  }

  &:focus {
    color: $brand-color-main;
    border-color: $brand-color-main;
  }

  &:active {
    color: $text-dark;
  }

  .icon {
    vertical-align: text-bottom;
  }
}

// Form day button
.btn-day {
  padding: 3px;
  width: 30px;
  font-size: 16px;
  font-weight: 300;
  @include button-variant(
    transparent,
    #f7b50033,
    transparent,
    $brand-color-main,
    $brand-color-main,
    #1a2b48
  );
  color: $brand-color-main;

  &:hover {
    color: $brand-color-main;
  }

  // &:focus {
  //   color: $body-color;
  //   border-color: $brand-color-main;
  // }

  &:active,
  &.active {
    color: $text-dark;
    font-weight: bold;
  }
}

// Show more button
.btn-dots {
  padding: 0;
  .dot {
    display: inline-block;
    margin: 0.25rem;
    width: 12px;
    height: 12px;
    vertical-align: middle;
    border-radius: 50%;
    background-color: $bg-color-1;
  }

  &:hover {
    .dot {
      background-color: $brand-color-main;
    }
  }
}

// Form button (Dropdown)
.btn-outline-form {
  @include button-variant(transparent, #1a2b48, $bg-color-2, #264477, $bg-color-2, #264477);
  &:focus {
    box-shadow: none !important;
  }
}
