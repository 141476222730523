@import '../variables';
//////////////////////////////////////////
// Product page
//////////////////////////////////////////
.stats-box.stats-box-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-details-box {
  padding: 30px 30px 0;
  height: 100%;
  background-color: $bg-color-1;

  table {
    tbody {
      tr {
        &:first-child {
          td {
            padding-top: 0;
            border: 0;
          }
        }
        td {
          position: relative;
          padding: 0.75rem 0.5rem;
          &:first-child {
            width: 118px;
            font-weight: bold;
          }
          .video-link {
            color: #00d9c4;
          }
          p.instructions-text {
            margin-right: 40px;
            .rtl & {
              margin-right: 0;
              margin-left: 40px;
            }
          }
          .edit-btn {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0;
            background-color: transparent;
            border: 0;
            .rtl & {
              right: auto;
              left: 0;
            }
          }
        }
      }
    }
  }
}
