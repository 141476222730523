@import '../variables';
//////////////////////////////////////////
// Page cover
//////////////////////////////////////////
.page-cover {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 346px;

  @include media-breakpoint-down(lg) {
    height: 230px;
  }

  .cover-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }

  .gradient-bg {
    position: relative;
    height: 142px;
    width: 100%;
    z-index: 1;
    background-image: linear-gradient(to bottom, #ffffff, #002d40 100%);
    mix-blend-mode: multiply;
  }

  .business-name {
    bottom: 30px;
    position: absolute;
    font-size: 54px;
    font-weight: bold;
    letter-spacing: -0.68px;
    z-index: 1;
    @include media-breakpoint-down(lg) {
      font-size: 42px;
    }
  }

  .branch-name {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.25px;
  }

  &.page-cover-round {
    margin-top: 40px;
    margin-bottom: 40px;
    flex-direction: column;
    align-items: center;
    height: auto;

    .cover-image {
      margin-bottom: 10px;
      position: static;
      height: 295px;
      width: 295px;
      border-radius: 50%;

      @include media-breakpoint-down(lg) {
        height: 195px;
        width: 195px;
      }
    }

    .business-name {
      position: static;
    }
  }

  &.page-cover-user {
    margin-top: 52px;
    margin-bottom: 40px;
    flex-direction: column;
    align-items: center;
    height: auto;
    text-align: center;

    .cover-image {
      margin-bottom: 30px;
      position: static;
      height: 94px;
      width: 94px;
      border-radius: 50%;
    }

    .welcome-text {
      margin-bottom: 1rem;
      font-size: 42px;
      font-weight: 300;
      letter-spacing: -0.53px;
      @include media-breakpoint-down(md) {
        padding: 0 1rem;
        font-size: 6vw;
      }
    }

    .description-text {
      font-size: 24px;
      font-weight: bold;
      @include media-breakpoint-down(md) {
        padding: 0 1rem;
        font-size: 4vw;
      }
    }
  }
}
