//////////////////////////////////////////
// Global variebles
//////////////////////////////////////////
$header-height: 74px;
$header-height-mobile: 50px;
$sub-header-height: 70px;
$sub-header-height-mobile: 48px;
$footer-height: 305px;

$grid-gutter-width: 12px;

//////////////////////////////////////////
// Colors
//////////////////////////////////////////
$body-bg: #000;
$body-color: #e2e6f4;
$teal: #00d9c4;
$yellow: #f7b500;
$yellow-muted: #ffe08d;
$green: #00c88a;
$red: #f00000;
$red-muted: #e22f40;
$pink: #eb5a87;
$dark: #1a2b48;

$text-dark: $dark;
$icons-color: #8798ad;

$brand-color-main: #ed1b2f;
$brand-color-main-muted: $red-muted;

$bg-color-1: #101826;
$bg-color-2: #0f1623;
$bg-color-3: #152032;
$bg-color-4: #0a0f18;

$border-color-1: #1a2b48;

$link-color: $brand-color-main;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$hr-border-color: #1a2b48;

//////////////////////////////////////////
// Btns + Inputs
//////////////////////////////////////////
$line-height-base: 1.5;

$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 0.75rem;
$input-btn-font-size: 15px;
$input-btn-line-height: $line-height-base;
$input-btn-focus-box-shadow: none;

//////////////////////////////////////////
// Buttons
//////////////////////////////////////////
// Allows for customizing button radius independently from global border radius
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

//////////////////////////////////////////
// tabs
//////////////////////////////////////////
$nav-tabs-link-active-color: #fff;
//////////////////////////////////////////
// Inputs
//////////////////////////////////////////
$input-bg: $bg-color-1;
$input-disabled-bg: $bg-color-3;

$input-color: $body-color;
$input-border-color: $border-color-1;
//$input-box-shadow:                      inset 0 1px 1px rgba($black, .075);

$input-focus-bg: $bg-color-2;
$input-focus-border-color: #264477;

$input-placeholder-color: #2e384d;

$input-btn-font-size: 15px;
/*
button-variant(
  $background,
  $border,
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 10%),
  $active-background: darken($background, 10%),
  $active-border: darken($border, 12.5%))
*/

//////////////////////////////////////////
// Radios & Checkboxes
//////////////////////////////////////////
$custom-control-indicator-size: 1rem;

$custom-control-indicator-border-color: $border-color-1;
$custom-control-indicator-checked-color: #fff;
$custom-control-indicator-checked-bg: $brand-color-main;
$custom-control-indicator-checked-border-color: $brand-color-main;

$custom-control-indicator-active-bg: $brand-color-main;
$custom-control-indicator-active-border-color: $brand-color-main;

$custom-control-indicator-focus-border-color: $border-color-1;

$custom-select-indicator-color: $brand-color-main;

//////////////////////////////////////////
// Dropdowns
//////////////////////////////////////////
$dropdown-font-size: 14px;
$dropdown-color: $body-color;
$dropdown-bg: $bg-color-1;
$dropdown-border-color: #1a2b48;
$dropdown-divider-bg: #1a2b48;
$dropdown-box-shadow: none;

$dropdown-border-radius: 0;
$dropdown-inner-border-radius: 0;

$dropdown-link-color: #e2e6f4;
$dropdown-link-hover-color: darken($dropdown-link-color, 5%);
$dropdown-link-hover-bg: $bg-color-4;

$dropdown-link-active-color: #e2e6f4;
$dropdown-link-active-bg: $bg-color-1;

$dropdown-link-disabled-color: darken($dropdown-link-color, 5%);

$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 10px;

$dropdown-divider-margin-y: 0.25rem;

$dropdown-header-color: #c5c7ca;

// Pagination
$pagination-line-height: 1;

$pagination-color: $link-color;
$pagination-bg: transparent;
$pagination-border-color: $brand-color-main;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-color: $text-dark;
$pagination-hover-bg: darken($brand-color-main, 10%);
$pagination-hover-border-color: $pagination-hover-bg;

$pagination-active-color: $text-dark;
$pagination-active-bg: $brand-color-main;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $text-dark;
$pagination-disabled-bg: $brand-color-main-muted;
$pagination-disabled-border-color: $pagination-disabled-bg;

//////////////////////////////////////////
// Modal
//////////////////////////////////////////
$modal-content-bg: $bg-color-1;
$modal-content-border-color: $bg-color-1;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-content-inner-border-radius: 0;

$modal-inner-padding: 4rem;

$modal-md: 630px;
