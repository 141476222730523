@import '../variables';

//////////////////////////////////////////
// Modals
//////////////////////////////////////////
.dark-modal {
  .modal-header {
    align-items: flex-end;
    flex-direction: column;
    .close-btn {
      color: $body-color;
      font-size: 18px;
      font-weight: 300;
      border: 0;
      background-color: transparent;
    }

    .modal-title {
      align-self: center;
      font-size: 30px;
      font-weight: bold;
    }
  }

  .modal-body {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include media-breakpoint-down(md) {
      padding: 1rem;
    }
  }

  .modal-footer {
    padding: $modal-inner-padding;
    padding-top: 1rem;
    padding-bottom: 3rem;
    justify-content: center;
    @include media-breakpoint-down(md) {
      padding: 1rem;
    }
  }

  .form-control {
    border-radius: 0;
  }
}
