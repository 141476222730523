@import '../variables';
//////////////////////////////////////////
// ngx daterange material
//////////////////////////////////////////
.custom-daterange-picker-theme {
  .md-drppicker {
    border-radius: 0;
    background-color: $bg-color-1;
    border: 1px solid $border-color-1;
    left: auto !important;
    right: auto !important;
    transform-origin: right top !important;
    transition: none !important;

    @include media-breakpoint-up(lg) {
      min-width: 605px !important;
    }

    .calendar {
      margin: 0 0 10px 4px;
      .rtl & {
        margin: 0 4px 10px 0;
      }
      .calendar-table {
        border-radius: 0;
        background-color: $bg-color-3;
        border: 1px solid $border-color-1;
        .table-condensed {
          .prev {
            height: 28px;
            min-width: 28px;
            background-color: $brand-color-main;
            border-radius: 28px;
            background-position: 47% center;
            .rtl & {
              transform: rotate(180deg);
            }
            &:hover {
              opacity: 1;
            }
          }
          .next {
            height: 28px;
            min-width: 28px;
            background-color: $brand-color-main;
            border-radius: 28px;
            background-position: 47% center;
            .rtl & {
              transform: rotate(0deg);
            }
            &:hover {
              opacity: 1;
            }
          }

          .dropdowns {
            width: auto;
            height: 28px;
            padding: 0 1.75rem 0 0.75rem;
            background: $custom-select-bg escape-svg($custom-select-indicator) no-repeat right
              $custom-select-padding-x center;
            background-size: 8px 10px;
            border: 1px solid $border-color-1;
            color: $body-color;
            font-style: normal;
            line-height: 27px;
            .rtl & {
              padding: 0 0.75rem 0 1.75rem;
              background: $custom-select-bg escape-svg($custom-select-indicator) no-repeat left
                $custom-select-padding-x center;
              background-size: 8px 10px;
            }
            select {
              height: 100%;
              border: 1px solid $border-color-1;
              background-color: $bg-color-1;
              option {
                color: $body-color;
              }
            }
          }
        }
        th {
          color: $info;
          font-style: italic;
          font-weight: normal;
          border: 0;
        }
        td {
          width: 2rem;
          height: 2rem;
          line-height: 2rem;
          color: $body-color;
          border-radius: 0;
          transform: none;
          opacity: 1;
          &:hover {
            background-color: $bg-color-4;
          }
          &.active {
            color: #fff;
            background-color: $brand-color-main;
          }
          &.in-range {
            background-color: rgba($brand-color-main, 0.8);
          }
          &.start-date {
            background-color: $brand-color-main;
            .rtl & {
              border-radius: 0 2rem 2rem 0;
            }
          }
          &.end-date {
            background-color: $brand-color-main;
            .rtl & {
              border-radius: 2rem 0 0 2rem;
            }
          }
          &.start-date.end-date {
            border-radius: 0;
          }
          &.off {
            color: #6c757d;
            background-color: transparent;
          }
        }
      }
      .calendar-time {
        .rtl & {
          direction: ltr;
        }
        .select {
          select {
            width: auto;
            height: 28px;
            padding: 0 1.75rem 0 0.75rem;
            background: $custom-select-bg escape-svg($custom-select-indicator) no-repeat right
              $custom-select-padding-x center;
            background-size: 8px 10px;
            border: 1px solid $border-color-1;
            color: $body-color;
            font-style: normal;
            line-height: 27px;
            appearance: none;
            font-size: 14px;
            .rtl & {
              padding: 0 0.75rem 0 1.75rem;
              background: $custom-select-bg escape-svg($custom-select-indicator) no-repeat left
                $custom-select-padding-x center;
              background-size: 8px 10px;
            }
            option {
              color: $body-color;
            }
          }
        }
      }
    }

    .ranges {
      ul {
        li {
          &:hover {
            background-color: $bg-color-3;
          }
          button {
            color: $body-color;
            .rtl & {
              text-align: right;
            }

            &.active {
              color: $text-dark;
              font-weight: bold;
              background-color: $brand-color-main;
            }
          }
        }
      }
    }

    .buttons {
      margin: 0;
      .rtl & {
        text-align: left;
      }
      .buttons_input {
        .btn {
          width: 56px;
          @extend.btn-yellow;
          border: 1px solid $brand-color-main;
          box-shadow: none;
          border-radius: 0;
          margin-left: 4px;
          .rtl & {
            margin-right: 4px;
            margin-left: 0;
          }

          &.btn-default {
            @extend .btn-default;
          }
        }
      }
    }
  }
}
