section.login-page {
  display: flex;
  flex-direction: row;
  height: 100vh;

  .form-container {
    display: flex;
    align-items: center;
    flex: 0 0 50%;
    @include media-breakpoint-down(md) {
      flex: 0 0 100%;
    }

    form.form-signin {
      width: 100%;
      max-width: 320px;
      padding: 15px;
      margin: auto;

      .login-title {
        font-size: 34px;
        font-weight: 300;
        line-height: 0.94;
        margin-bottom: 10px;
      }

      .login-description {
        margin-bottom: 45px;
        color: #8798ad;
        font-size: 15px;
        line-height: 1.47;
      }

      label {
        font-size: 14px;
      }

      .btn-login {
        padding-top: 13px;
        padding-bottom: 13px;
        font-weight: bold;
        font-size: 15px;
        @include button-variant(transparent, $teal, transparent, $teal, $teal, $teal);
        color: $teal;
        &:hover {
          color: $body-color;
        }
        // &:focus {
        //   color: $teal;
        //   border-color: $brand-color-main;
        // }
        &:active {
          color: $text-dark !important;
        }
      }

      .sign-up-link {
        color: $teal;
      }

      .sign-up-text {
        font-size: 12px;
      }
    }
  }

  .image-container {
    flex: 0 0 50%;
    background-image: url(../../assets/images/login-page-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
