@import '../variables';

//////////////////////////////////////////
// Search bar
//////////////////////////////////////////
.search-bar {
  margin-top: 6px;
  margin-bottom: 6px;
  .form-group {
    margin-bottom: 0;

    .form-control {
      background-color: $bg-color-4;
      border-color: $bg-color-4;
      background-clip: border-box;

      &::placeholder {
        color: #8798ad;
      }
    }
  }
}
