@import '../variables';
//////////////////////////////////////////
// Gallery
//////////////////////////////////////////
.gallery-item {
  margin-bottom: 30px;

  .image-holder {
    position: relative;
    background-color: #fff;
    height: 100%;
    &:hover {
    }
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .title-holder {
    margin-top: 6px;
    height: 28px;
    .title {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: -0.3px;
      color: $body-color;
      @include media-breakpoint-down(md) {
        font-size: 6vw;
        text-align: center;
      }
    }
  }

  &.gallery-item-create-new {
    .image-holder {
      overflow: hidden;
      .image {
        filter: blur(5px);
      }
    }
    .create-new {
      position: absolute;
      top: 55%;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#0d141f, 0.87);

      p {
        margin-bottom: 0;
        color: $brand-color-main;
        font-size: 18px;
        font-weight: bold;
        @include media-breakpoint-down(md) {
          font-size: 4vw;
        }
      }
    }
  }

  .video-lenght {
    position: absolute;
    top: 10px;
    right: 0;
    width: 100px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    .rtl & {
      right: auto;
      left: 0;
    }
    span {
      font-size: 20px;
      font-weight: 300;
      color: $body-color;
    }
  }

  .video-play {
    display: none;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  .edit-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 0;
    background-color: transparent;
    border: 0;
    .rtl & {
      right: auto;
      left: 10px;
    }
  }

  &.video-item:hover {
    .image-holder {
      .image {
        opacity: 0.65;
      }
    }
    .video-play {
      display: block;
    }
  }
}
