@import '../variables';

//////////////////////////////////////////
// Tabs
//////////////////////////////////////////

.nav-custom {
  background-color: $bg-color-1;
  .nav-item {
    flex: 0 0 calc(100% / 4);
    text-align: center;
    .nav-link {
      position: relative;
      padding: 0.3125rem 0;
      color: #636f7e;
      font-size: 20px;
      letter-spacing: -0.25px;

      @include media-breakpoint-down(lg) {
        font-size: 16px;
      }
      @include media-breakpoint-down(md) {
        font-size: 4vw;
      }

      &.active {
        color: $body-color;
        font-weight: bold;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          border-bottom: 4px solid $brand-color-main;
        }
      }
    }
  }
}

.custom-three-tabs {
  .nav-custom {
    .nav-item {
      flex: 0 0 calc(100% / 3) !important;
    }
  }
}
