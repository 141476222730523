@import '../variables';
//////////////////////////////////////////
// Dropdowns
//////////////////////////////////////////
.dropdown {
  .dropdown-toggle {
    position: relative;
    outline: none;
    &::after {
      content: url(../../assets/images/chevron-down.svg);
      position: absolute;
      top: 50%;
      right: 14px;
      border: none;
      width: 12px;
      vertical-align: baseline;
      transform: translate(0, -50%);

      .rtl & {
        right: auto;
        left: 14px;
      }
    }
  }
  .dropdown-menu {
    padding: 0;
    min-width: 180px;

    .dropdown-item {
      border-top: 1px solid #1a2b48;
      &:first-child {
        border-top: 0;
      }
    }
  }
}

.dropdown.dropdown-link-style {
  .dropdown-toggle {
    color: #8798ad;
    border: 0;
    background-color: transparent;
    &:after {
      position: static;
      transform: translate(0, -10%);
      line-height: 100%;
    }
  }

  .dropdown-menu {
    padding: 0.25rem 10px 0.5rem;

    .rtl & {
      left: auto !important; // rtl position fix
    }

    .dropdown-item {
      border: 0;
      &::before {
        content: '';
        display: inline-block;
        margin-right: 16px;
        width: 12px;
        height: 12px;
        border: solid 1px #979797;
        border-radius: 6px;
        vertical-align: middle;

        .rtl & {
          margin-left: 16px;
          margin-right: 0;
        }
      }

      &:hover {
        &::before {
          border-color: $brand-color-main;
          background-color: $brand-color-main;
        }
      }

      &.active {
        &::before {
          border-color: $green;
          background-color: $green;
        }
      }

      &.business-item {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 1.69px;
      }
    }
  }
}

.dropdown.dropdown-custom-style {
  .dropdown-toggle {
    min-width: 325px;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    text-align: initial;
    padding-right: 2.25rem;

    .rtl & {
      padding-left: 2.25rem;
      padding-right: 0.75rem;
    }

    .circle {
      margin-bottom: 1px;
    }

    .business-name {
      font-weight: bold;
    }

    &.link-style-toggle {
      padding: 0;
      color: #8798ad;
      border: 0;
      background-color: transparent;
      &:after {
        position: static;
        transform: translate(0, -10%);
        line-height: 100%;
      }
    }
  }

  .dropdown-menu {
    .rtl & {
      left: 0; // rtl position fix
    }

    .swiper-container {
      height: 100%;
      max-height: calc(100vh - 255px);
      @include media-breakpoint-up(xl) {
        max-height: 80vh;
        height: auto;
      }

      .swiper-wrapper {
        .swiper-slide {
          padding: 0;
          height: auto;
        }
      }
    }

    .search-bar {
      margin: 0;
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
      background-color: #101b2f;
    }

    .dropdown-divider {
      margin: 0;
    }

    .dropdown-item {
      border: 0;
      outline: 0;
      background-color: $bg-color-1;
      &::before {
        content: '';
        display: inline-block;
        margin-right: 16px;
        width: 12px;
        height: 12px;
        border: solid 1px #979797;
        border-radius: 6px;
        vertical-align: middle;

        .rtl & {
          margin-left: 16px;
          margin-right: 0;
        }
      }

      &:hover {
        &::before {
          border-color: $brand-color-main;
          background-color: $brand-color-main;
        }
      }

      &.active {
        color: $green;
        &::before {
          border-color: $green;
          background-color: $green;
        }
      }

      &.business-item {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 1.69px;

        &::before {
          display: none;
        }

        // Business name
        .business-name {
          display: inline-block;
          width: calc(100% - 30px);
          vertical-align: middle;
        }

        // Collapse icon
        .collapse-icon {
          display: inline-block;
          width: 30px;
          height: 30px;
          background-image: url(../../assets/images/chevron-down.svg);
          background-size: 12px 7px;
          background-position: center;
          background-repeat: no-repeat;
          vertical-align: middle;

          &[expanded='true'] {
            background-image: url(../../assets/images/chevron-up-yellow.svg);
          }
        }
      }
    }
  }
}
