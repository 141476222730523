@import '../variables';

//////////////////////////////////////////
// Side bar
//////////////////////////////////////////
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  margin: #{$header-height} 0 0; /* Height of navbar */
  width: 56px;
  background-color: $bg-color-4;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  z-index: 1029; /* Behind the navbar */
  transition: width 300ms ease-out;

  @include media-breakpoint-down(md) {
    margin: #{$header-height-mobile} 0 0; /* Height of navbar */
    width: 0;
  }

  &.open {
    width: 310px;
    @include media-breakpoint-down(lg) {
      width: 218px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .user-details {
      .avatar {
        .avatar-image {
          opacity: 1;
        }
      }

      .text {
        opacity: 1;
      }

      .logout {
        opacity: 1;
      }
    }
    .nav {
      .nav-item {
        .nav-link {
          color: $body-color;
          &.active {
            color: $brand-color-main;
          }
        }
      }
    }
  }

  .rtl & {
    left: auto;
    right: 0;
  }

  // User avatar & details
  .user-details {
    display: flex;
    align-items: center;
    height: $sub-header-height;
    @include media-breakpoint-down(lg) {
      height: $sub-header-height-mobile;
    }

    @include media-breakpoint-down(md) {
      height: 80px;
      background-color: $bg-color-3;
    }

    .avatar {
      padding: 0 12px;
      .avatar-image {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        opacity: 1;
        transition: opacity 200ms ease-in-out;
        @include media-breakpoint-down(md) {
          height: 40px;
          width: 40px;
        }
      }
    }

    .text {
      white-space: nowrap;
      opacity: 0;
      transition: opacity 200ms ease-in-out;
      .welcome-text {
        margin-bottom: 0;
        font-size: 15px;
        font-weight: 300;
        line-height: 1.3;
        color: #8798ad;

        .rtl {
          text-align: right;
        }
      }
      .username {
        margin-bottom: 0;
        font-size: 15px;
      }
    }

    .logout {
      margin-left: auto;
      opacity: 0;
      transition: opacity 200ms ease-in-out;
      .rtl & {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }

  // change business
  .change-business {
    white-space: nowrap;
    //padding: 1rem;
    background-color: $bg-color-3;
    border-top: 1px solid #1a2b48;
    border-bottom: 2px solid #0d1524;
    p {
      margin-bottom: 0.25rem;
      font-weight: bold;
      color: $pink;
    }
    .dropdown {
      .dropdown-menu {
        margin: 0;
        box-sizing: border-box;
      }
      .link-style-toggle.dropdown-toggle {
        width: 100%;
        padding: 1rem;
      }
    }
  }

  // Menu
  .nav {
    .nav-item {
      .nav-link {
        position: relative;
        padding: 10px 10px;
        font-size: 15px;
        font-weight: normal;
        white-space: nowrap;
        color: transparent;
        transition: color 200ms ease-in-out;

        &:hover {
          background-color: $bg-color-3;
        }

        &.active {
          font-weight: bold;
          background-color: $bg-color-3;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 3px;
            background-color: $brand-color-main;

            .rtl & {
              left: auto;
              right: 0;
            }
          }

          img {
            display: none;
            &.active {
              display: inline-block;
            }
          }

          fa-icon {
            color: $brand-color-main;
          }
        }

        img {
          width: 36px;
          height: 36px;
          margin-right: 8px;

          &.active {
            display: none;
          }

          .rtl & {
            margin-right: 0;
            margin-left: 8px;
          }
        }

        fa-icon {
          display: inline-block;
          margin-right: 8px;
          width: 36px;
          height: 36px;
          color: #fff;
          vertical-align: middle;
          text-align: center;

          .rtl & {
            margin-right: 0;
            margin-left: 8px;
          }

          svg {
            margin: 8px auto;
            font-size: 20px;
          }
        }
      }
    }
  }

  .swiper-container {
    height: 100%;
    .swiper-wrapper {
      .swiper-slide {
        height: auto;
      }
    }
  }
}

// .sidebar-sticky {
//   position: relative;
//   top: 0;
//   height: calc(100vh - #{$header-height + $sub-header-height});
//   overflow-x: hidden;
//   overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
// }

// @supports ((position: -webkit-sticky) or (position: sticky)) {
//   .sidebar-sticky {
//     position: -webkit-sticky;
//     position: sticky;
//   }
// }
